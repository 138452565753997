import { NextImage } from "@/components/common/NextImage/NextImage";
import { useSharedData } from "@/react-app/contexts/SharedData";
import { useTracking } from "@/react-app/contexts/Tracking";
import { useTranslations } from "@/react-app/contexts/Translations/TranslationsContext";
import Breakpoints from "@/react-app/styles/breakpoints.config";
import { xxlTheme } from "@/react-app/styles/xxl-theme";
import { BannerVideo } from "@/react-components/CategoryContent/CategoryComponents/BannerVideo";
import { SkeletonWrapper } from "@/react-components/Common";
import { CaretIcon } from "@/react-components/Common/Icons/Caret";
import { Slider } from "@/react-components/Common/Slider";
import { CategoryIcon } from "@/react-components/MegaMenu/Mobile/CategoryIcon";
import { createProductItems } from "@/react-components/PersonalizedProductList";
import { ListStyles } from "@/react-components/PersonalizedProductList/personalized-product-list-helper";
import {
  LinkDisplayName,
  PersonalizedWrapper,
  Link as StyledLink,
} from "@/react-components/PersonalizedProductList/ProductList.styled";
import {
  cardHeightMobile,
  nonHoverableCardHeight,
} from "@/react-components/Product/Product.styled";
import {
  BREAKPOINTS,
  useBreakpoint,
} from "@/react-hooks/useBreakpoint/useBreakpoint";
import { useXxlMediaQuery } from "@/react-hooks/useXxlMediaQuery";
import { isNotEmpty, isNotNull, isNotNullOrUndefined } from "@xxl/common-utils";
import type {
  ContentProductCarousel as ContentProductCarouselDataProps,
  Image,
  InternalIdentifier,
  Link,
  Video,
} from "@xxl/content-api";
import { ArrowRight } from "@xxl/icons";
import {
  RecommendationsParametersStrategyEnum,
  type RecommendationsResponseResponse,
} from "@xxl/recommendations-api";
import { useEffect, useState } from "react";
import {
  CarouselWrapper,
  Description,
  DescriptionLinkIndicator,
  DescriptionLinkWrapper,
  Heading,
  HeadingTextWrapper,
  ImageWrapper,
  MediaWrapper,
  SliderWrapper,
  Wrapper,
} from "./ContentProductCarousel.styled";

const { spaces } = xxlTheme;

type ContentProductCarouselProps = {
  personalizedData: RecommendationsResponseResponse;
  contentData: ContentProductCarouselDataProps & InternalIdentifier;
  showLoadingStatus?: boolean;
};

const DEFAULT_IMAGE_POSITION = "left";
const IMAGE_POSITION_RIGHT = "right";

export const ContentProductCarouselComponent: React.FunctionComponent<
  ContentProductCarouselProps
> = ({ personalizedData, contentData, showLoadingStatus = false }) => {
  const {
    featureToggles: { toggle_favorites, toggle_products_as_package_quantity },
    pageType,
    siteDefaultLanguage,
  } = useSharedData().data;
  const trackers = useTracking();
  const {
    productRecommendation,
    visualMedia,
    descriptionField,
    imagePosition = DEFAULT_IMAGE_POSITION,
    titleField,
    buttons,
  } = contentData;
  const breakpoint = useBreakpoint();
  const { t } = useTranslations();

  const { productList } = personalizedData;

  const gtmProductListName = `${pageType}_${String(
    contentData.productRecommendation?.strategy
  )}`;

  const productItems = createProductItems({
    listName: gtmProductListName,
    listStyle: ListStyles.HORIZONTAL,
    productsCount: contentData.productRecommendation?.productsCount ?? 8,
    prioritizeImage: false,
    pageType,
    trackers,
    siteDefaultLanguage,
    toggleProductsAsPackageQuantity: toggle_products_as_package_quantity,
    toggleFavorites: toggle_favorites,
    t,
    products: productList,
  }).filter(isNotNull);
  const hasImage =
    "image" in visualMedia && isNotNullOrUndefined(visualMedia.image);
  const imageData = hasImage ? (visualMedia.image as Image) : undefined;
  const hasVideo =
    "video" in visualMedia && isNotNullOrUndefined(visualMedia.video);
  const videoData = hasVideo ? (visualMedia.video as Video) : undefined;
  const hasDescription = isNotNullOrUndefined(descriptionField?.description);
  const [numberOfSlides, setNumberOfSlides] = useState(2);
  const [mediaWidth, setMediaWidth] = useState("0");
  const [sliderWidth, setSliderWidth] = useState("100%");
  const [arrowsSpacing, setArrowsSpacing] = useState(0);
  const [iconSize, setIconSize] = useState(36);
  const imageSizes = `(max-width: ${Breakpoints.tablet}px) 100vw, (max-width: ${
    Breakpoints.laptop - 1
  }px) 33vw, (max-width: ${Breakpoints.desktop - 1}px) 25vw, 33vw`;

  const isLaptop = useXxlMediaQuery("LaptopMediaQuery");
  const cardHeight = isLaptop ? nonHoverableCardHeight : cardHeightMobile;

  useEffect(() => {
    if (isNotNullOrUndefined(imageData) && breakpoint === BREAKPOINTS.laptop) {
      setNumberOfSlides(3);
    } else if (
      isNotNullOrUndefined(imageData) &&
      (breakpoint === BREAKPOINTS.desktop ||
        breakpoint === BREAKPOINTS.bigDesktop)
    ) {
      setNumberOfSlides(4);
    } else {
      setNumberOfSlides(2);
    }

    if (isNotNullOrUndefined(videoData)) {
      if (
        breakpoint === BREAKPOINTS.mobile ||
        breakpoint === BREAKPOINTS.tablet
      ) {
        setMediaWidth(`calc(100dvw - 2 * ${spaces.smallRegular})`);
        setSliderWidth("100%");
        setNumberOfSlides(3);
      } else if (breakpoint === BREAKPOINTS.laptop) {
        setMediaWidth("546px");
        setSliderWidth(`calc(100dvw - 2 * ${spaces.huge} - 546px)`);
        setArrowsSpacing(546);
        setNumberOfSlides(2);
      } else if (breakpoint === BREAKPOINTS.desktop) {
        setMediaWidth("666px");
        setSliderWidth(`calc(100dvw - 2 * ${spaces.huge} - 666px)`);
        setArrowsSpacing(666);
        setNumberOfSlides(3);
      } else {
        setSliderWidth("666px");
        setArrowsSpacing(666);
        setNumberOfSlides(3);
      }
    }
    if (isNotNullOrUndefined(imageData)) {
      if (breakpoint === BREAKPOINTS.mobile) {
        setMediaWidth(`calc(100dvw - 2 * ${spaces.smallRegular})`);
        setSliderWidth("100%");
      } else if (breakpoint === BREAKPOINTS.tablet) {
        setMediaWidth("280px");
        setSliderWidth(`calc(100dvw - 2 * ${spaces.smallRegular} - 280px)`);
      } else if (breakpoint === BREAKPOINTS.laptop) {
        setMediaWidth("320px");
        setSliderWidth(`calc(100dvw - 2 * ${spaces.huge} - 320px)`);
        setArrowsSpacing(320);
      } else if (breakpoint === BREAKPOINTS.desktop) {
        setMediaWidth("440px");
        setSliderWidth(`calc(100dvw - 2 * ${spaces.huge} - 440px)`);
        setArrowsSpacing(440);
      } else {
        setMediaWidth("440px");
        setSliderWidth("892px");
        setArrowsSpacing(440);
      }
    }
    if (isNotNullOrUndefined(titleField.categoryTitle)) {
      setIconSize(breakpoint === BREAKPOINTS.mobile ? 36 : 48);
    }
  }, [breakpoint, imageData, titleField.categoryTitle, videoData]);

  const categoriesArray = isNotNullOrUndefined(titleField.categoryTitle)
    ? titleField.categoryTitle
    : null;

  const category = isNotNullOrUndefined(categoriesArray)
    ? categoriesArray[0]
    : null;

  const titleText = isNotNullOrUndefined(titleField.title)
    ? titleField.title
    : isNotNull(category)
      ? category.displayName
      : "";

  const descriptionButtons = isNotNullOrUndefined(descriptionField?.buttons)
    ? (descriptionField.buttons as Link[])
    : null;

  const descriptionButton = isNotNull(descriptionButtons)
    ? descriptionButtons[0]
    : null;

  const titleButtons = isNotNullOrUndefined(buttons) ? buttons : null;
  const titleButton = isNotNullOrUndefined(titleButtons)
    ? titleButtons[0]
    : null;

  if (productItems.length === 0) {
    return null;
  }

  const Media = () => (
    <MediaWrapper
      componentWidth={mediaWidth}
      paddingLeft={imagePosition !== DEFAULT_IMAGE_POSITION}
      paddingRight={imagePosition === DEFAULT_IMAGE_POSITION}
      hasVideo={hasVideo}
      data-testid="content-product-carousel-media"
    >
      {hasImage && isNotNullOrUndefined(imageData) && (
        <DescriptionLinkWrapper href={descriptionButton?.url}>
          {isNotEmpty(imageData.url) && (
            <ImageWrapper hasDescription={hasDescription}>
              <NextImage
                src={imageData.url}
                width={416}
                height={235}
                sizes={imageSizes}
                alt={imageData.alt ?? ""}
              />
            </ImageWrapper>
          )}

          {hasDescription && (
            <Description>{descriptionField.description}</Description>
          )}
          {isNotNull(descriptionButton) && (
            <DescriptionLinkIndicator>
              {descriptionButton.displayName}
              <ArrowRight />
            </DescriptionLinkIndicator>
          )}
        </DescriptionLinkWrapper>
      )}
      {hasVideo && isNotNullOrUndefined(videoData) && (
        <BannerVideo
          origin={videoData.origin}
          url={videoData.url}
          isContentProductCarousel={true}
        />
      )}
    </MediaWrapper>
  );

  return (
    <PersonalizedWrapper
      carouselType={
        isNotNullOrUndefined(productRecommendation?.strategy)
          ? (productRecommendation.strategy as RecommendationsParametersStrategyEnum)
          : RecommendationsParametersStrategyEnum.bestseller
      }
      colorTheme={productRecommendation?.colorTheme}
      isNextApp={true}
      data-testid="content-product-carousel"
    >
      <SkeletonWrapper
        style={{ minHeight: cardHeight }}
        isLoading={productList === undefined && showLoadingStatus}
      >
        <Wrapper>
          <Heading>
            {isNotNull(category) && (
              <CategoryIcon
                code={String(category.ecomCode)}
                size={iconSize}
                showFallback={true}
              />
            )}
            <HeadingTextWrapper>{titleText}</HeadingTextWrapper>
            {isNotNull(titleButton) && (
              <StyledLink
                href={titleButton.url}
                carouselType={
                  isNotNullOrUndefined(productRecommendation?.strategy)
                    ? (productRecommendation.strategy as RecommendationsParametersStrategyEnum)
                    : RecommendationsParametersStrategyEnum.bestseller
                }
                colorTheme={productRecommendation?.colorTheme}
              >
                <LinkDisplayName>{titleButton.displayName}</LinkDisplayName>
                <CaretIcon direction="right" />
              </StyledLink>
            )}
          </Heading>
          <CarouselWrapper hasVideo={hasVideo}>
            {(isNotNullOrUndefined(imageData) ||
              isNotNullOrUndefined(videoData)) &&
              imagePosition === DEFAULT_IMAGE_POSITION && <Media />}
            <SliderWrapper
              componentWidth={sliderWidth}
              hasVideo={hasVideo}
              imagePosition={String(imagePosition)}
            >
              <Slider
                items={productItems}
                navigation={
                  breakpoint === BREAKPOINTS.mobile ||
                  breakpoint === BREAKPOINTS.tablet
                    ? "DOTS"
                    : "ARROWS"
                }
                slidesConfig={{
                  perView: numberOfSlides,
                  spacing: 8,
                }}
                showArrowsInContainer={true}
                arrowsContainerProps={{
                  leftMargin:
                    imagePosition === DEFAULT_IMAGE_POSITION
                      ? arrowsSpacing
                      : undefined,
                  rightMargin:
                    imagePosition === IMAGE_POSITION_RIGHT
                      ? arrowsSpacing
                      : undefined,
                }}
              />
            </SliderWrapper>
            {(isNotNullOrUndefined(imageData) ||
              isNotNullOrUndefined(videoData)) &&
              imagePosition === IMAGE_POSITION_RIGHT && <Media />}
          </CarouselWrapper>
        </Wrapper>
      </SkeletonWrapper>
    </PersonalizedWrapper>
  );
};
