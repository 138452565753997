import { color } from "@xxl/theme";
import type * as CSSType from "csstype";
import type { ReactElement } from "react";
import type { VariantType } from "react-app/src/components/Common/XxlButton/XxlButton";
import type { HandlerProps } from "../PersonalizedProductLists/Handler/Handler.helper";
import type { Coordinates } from "../common/NextImage/NextImage.types";

type Text = {
  text?: string;
  color: string;
};

type CommonProps = {
  backgroundColor: CSSType.Property.Color;
  Slider: ReactElement<HandlerProps>;
  title: Text;
  description?: Text;
  image?: {
    desktopSrc: string;
    alt: string;
    mobileSrc?: string;
    hotspot?: Coordinates;
  };
  TopComponent?: React.ReactNode;
};

type HeaderAsLink = CommonProps & {
  headerType: "HEADER_AS_LINK";
  link: {
    href: string;
  };
};

type ButtonAsLink = CommonProps & {
  headerType: "BUTTON_AS_LINK";
  button: {
    backgroundColor: typeof color.webBlack.hex | typeof color.white.hex;
    href: string;
    text: string;
  };
};

export type CampaignCarouselProps = HeaderAsLink | ButtonAsLink;

export const getUniqueProps = (props: CampaignCarouselProps) => {
  return {
    button: props.headerType === "BUTTON_AS_LINK" ? props.button : null,
    link: props.headerType === "HEADER_AS_LINK" ? props.link : null,
  };
};

export const getButtonVariant = (
  isLaptop: boolean,
  buttonColor: CSSType.Property.Color
): VariantType => {
  if (isLaptop) {
    return buttonColor === color.webBlack.hex
      ? "secondaryInBanner"
      : "tertiaryInBanner";
  }

  return "secondaryInBanner";
};
